.e-btn-sb-icons {
  font-family: "button-icons";
  line-height: 1;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.e-add-icon::before {
  content: "\e70a";
}

.row {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.addTBtn {
  margin: 8px 0 0 -17px !important;
}
