.paper {
  padding: 15px 16px;
}

.paper button {
  display: none;
}

.paper:hover button {
  display: inline-flex;
  position: absolute;
  top: -2px;
  right: 3px;
  width: 25px;
  height: 25px;
  min-height: 20px;
}

.hidden {
  display: none;
}
.debit {
  color: red;
}
.credit {
  color: green;
}
.legGrid {
  padding-bottom: 3px;
}
